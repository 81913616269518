// Base
@import "feelbettr/variables.less";
@import "themes/uikit-min-theme-default.less";
@import "themes/base.less";
@import "themes/typography.less";
@import "themes/contact.less";
@import "themes/header-overlay.less";

@bg-color: #fafafa;
@link-hover-color: @primary;
@global-font-size: 14px;
@global-font-family: "Lato",sans-serif;

html {
	background:@bg-color;
	
}

html,body,li {
	font-family: @global-font-family;
	font-weight:normal;
	text-rendering:optimizeLegibility;
	text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased
}

h1 {
	font-size:2rem
}

h3 {
	span {
		background:@bg-color
	}
}

.uk-height-small {
	padding-top:30px;
	height:100px !important;
}

h1,h2,h3,h4,h5,h6,blockquote {
	font-family: 'Raleway', serif;
	text-rendering:optimizeLegibility;
	text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased
}

h2.site-title {
	font-family: 'Oswald', sans-serif;
}

.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
	font-family: @global-font-family;
}

h2.line-behind {
	position:relative;
	background:#fff;
	
	span {
		background:#fff;
		display:inline-block;
		position:relative;
		z-index:2;
		padding:0 15px 0 15px;
		font-size:inherit;
		text-transform: uppercase
	}

	&:after {
		content:"";
		height:1px;
		background:rgba(0,0,0,0.1);
		width:100%;
		position:absolute;
		left:0;
		top:55%;
		z-index:1;
	}
}

footer.uk-light {
	a {
		color:#fff
	}
}

#header {
	h2.site-title {
		margin-bottom:15px !important
	}
}

// Dropdown menu
#header {
	.uk-navbar-dropdown {
		background:rgba(0,0,0,0.8) !important;
		width:400px;
		a {
			color:#fff !important;
		}
	}
}

.is-light-text {
	.uk-button {
		box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.06);
		background:none !important;
    	border-radius: 3px;

	}
}

.is-bg-grey {
    background-color: #FAFAFA !important;
}

.lifestyle-shadow {
	.is-boxes {
		float:none;
	}

	.is-bg-grey {
		float:none;
	}
}

.widget-list,
.widget-grid {
	.uk-card-default {
		float: left;
	    width: 100%;
	    background: #fefefe;
	    padding: 20px;
	    box-shadow: 0 2px 6px rgba(0,0,0,0.06);

	    margin-bottom: 30px;
	}
}

.lifestyle-shadow {
	background: #fafafa !important;

		.is-container,
		.uk-container {
			> div {
				float: left;
			    width: 100%;
			    background: #fefefe;
			    padding: 20px;
			    box-shadow: 0 2px 6px rgba(0,0,0,0.06);
			    margin-top:30px;
			    margin-bottom: 30px;

			    h1,h2 {
			    	position:relative;
			    	margin-bottom:10px;
			    	padding-bottom:10px
			    }

			    h2:after,
			    h1:after {
			    	content: "";
				    height: 1px;
				    background: #348899;
				    width: 100px;
				    position: absolute;
				    left: 0;
				    bottom: 0;
			    }
            }
            
            .uk-container > div {
                float: left;
			    width: 100%;
			    background:none;
			    padding: 0;
			    box-shadow: none;
			    margin-top:0;
			    margin-bottom: 0;
            }
		}

		.uk-grid {
			margin:0;
		}

		.uk-grid div:first-child {
			padding-left:0
		}
}

#two-line-menu li a {
	font-weight:bold;
}

#two-column {
	background: #fefefe;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
    width: 100%;
    max-width: 1140px;
    margin: 40px auto;
    overflow: hidden;

	.is-container {
		padding:0;

		> div {
			float: left;
		    width: 100%;
		    background: none;
		    padding: 0px;
		    box-shadow:none !important;
		    margin-bottom: 0px;
		}
	}
}

.translate-dropdown {

	button {
		background-color:fade(#fff, 5%) !important;
		border-radius:3px;
		padding:10px
	}
	i {
		background:none
	}

	select{
		color:#000 !important;
		border: 1px solid rgba(0,0,0,0.06);
    	width: 100%;
	}

	p {
		font-weight:bold;
		color:#000 !important;
	}

	.uk-dropdown {
		box-shadow: 0 2px 6px rgba(0,0,0,0.2);
		width:300px;
    }
    
   
}

.menu-collapse-2 {
    .language-selector-wrapper {

        @media screen and (max-width:960px) {
            margin-right:130px
        }

        @media screen and (max-width:620px) {
            margin-top:0 !important
        }
    }

    @media screen and (max-width:620px) {
        #responsive-menu-icon {
            margin-top:22px !important
        }
    }
}

@media screen and (min-width: 640px){
    #responsive-menu-icon {
        margin-top:40px !important
    }
}